import React from "react";
import { useTranslation } from "react-i18next";

const Cta = () => {
  const whatsappNumber = "+971508922504"; // Change this to your WhatsApp number
  const whatsappMessage = "Hello! I am interested in your services."; // Predefined message
  const { t, i18n } = useTranslation();

  // Determine the direction (LTR or RTL) based on the current language
  const isRTL = i18n.dir() === "rtl";

  // Inline styles for the SVG rotation
  const svgStyles = {
    transform: isRTL ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 0.5s ease-in-out",
    marginRight: isRTL ? "0.5rem" : "0",
  };

  return (
    <div className="w-full flex items-center justify-center text-white cta">
      <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">
        <div className="w-full flex flex-col lg:flex-row lg:justify-around">
          <div className="mb-4">
            <p className="text-2xl md:text-4xl font-bold mb-4">
              {t("underClientsSection.title_to_ask")}
            </p>
            <p className="text-lg md:text-2xl">
              {t("underClientsSection.sub_title_to_ask")}
              <span className="font-black">
                {" "}
                {t("underClientsSection.together")}
              </span>
            </p>
          </div>

          <div className="w-full lg:w-72 pt-6 lg:mx-12">
            <a
              href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
                whatsappMessage
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-transparent border hover:bg-black hover:border-black text-white justify-center text-center rounded-lg px-10 py-3 flex items-center group"
            >
              {t("underClientsSection.button_send_a_message")}
              <svg
                className="w-5 h-5 ml-1  svg-flip group-hover:translate-x-2 duration-500 ease-in"
                style={svgStyles}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;