import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaEnvelope,
} from "react-icons/fa"; // React Icons
import { useTranslation } from "react-i18next";
import Logo from "../../src/images/Taab4-logo.jpeg";

const Footer = () => {
  const clientImageStyle = {
    height: "13rem",
    width: "auto",
  };

  const { t, i18n } = useTranslation();
  return (
    <footer>
      <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-10">
        {/* Top area: Blocks */}
        <div className="flex flex-col items-center sm:flex-row sm:justify-around gap-6 py-8 md:py-12">
          {/* Logo Block */}
          <div className="text-md font-medium text-gray-600 text-center sm:text-left">
            <div
              style={clientImageStyle}
              className="overflow-hidden p-3 flex justify-center sm:justify-start transition-all ease-in-out opacity-100"
            >
              <img
                src={Logo}
                alt="TaaB4 Company Logo" // Updated alt text for better accessibility
                className="rounded-3xl duration-1000 transition-all ease-in-out"
              />
            </div>
          </div>

          {/* Social Media Links Block */}
          <div className="flex flex-col text-center font-bold uppercase text-black">
            <h5 className="text-2xl text-black font-bold mb-5">
              <span className="font-black uppercase">
                {t("footer.follow_us")}
              </span>
            </h5>
            <div className="mx-auto">
              <ul className="flex justify-center gap-4">
                {/* Email */}
                <li className="mx-2">
                  <a
                    href="mailto:info@taab4.com"
                    className="flex justify-center items-center text-black text-white bg-gray-800 rounded-full transition duration-150 ease-in-out p-2 hover:bg-white hover:text-black"
                    aria-label="Email" // Added aria-label for email icon
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaEnvelope size={30} />
                  </a>
                </li>
                {/* LinkedIn */}
                <li className="mx-2">
                  <a
                    href="https://www.linkedin.com/company/taab4-company/?viewAsMember=true"
                    className="flex justify-center items-center text-black text-white bg-blue-700 rounded-full transition duration-150 ease-in-out p-2 hover:text-black hover:bg-white"
                    aria-label="LinkedIn" // Added aria-label for LinkedIn icon
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin size={30} />
                  </a>
                </li>

                {/* WhatsApp */}
                <li className="mx-2">
                  <a
                    href="https://wa.me/+971508922504"
                    className="flex justify-center items-center hover:text-black text-white bg-green-500 rounded-full transition duration-150 ease-in-out p-2 hover:text-black hover:bg-white"
                    aria-label="WhatsApp" // Added aria-label for WhatsApp icon
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp size={30} />
                  </a>
                </li>

                {/* Instagram */}
                <li className="mx-2">
                  <a
                    href="https://www.instagram.com/_taab4/"
                    className="flex justify-center items-center text-black text-white bg-pink-500 rounded-full transition duration-150 ease-in-out p-2 hover:text-black hover:bg-white"
                    aria-label="Instagram" // Added aria-label for Instagram icon
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram size={30} />
                  </a>
                </li>

                {/* Facebook */}
                <li className="mx-2">
                  <a
                    href="https://www.facebook.com/profile.php?id=61562403166741"
                    className="flex justify-center items-center text-black text-white bg-blue-600 rounded-full transition duration-150 ease-in-out p-2 hover:text-black hover:bg-white"
                    aria-label="Facebook" // Added aria-label for Facebook icon
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook size={30} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Bottom area: Copyright */}
        <div className="text-center py-4">
          <div className="text-sm font-semibold">
            &copy; {new Date().getFullYear()}{" "}
            <a href="#" className="hover:text-gray-900">
              Technologies Company
            </a>
            . All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;