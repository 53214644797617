import React, { useState, useEffect } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa'; 
import { AiOutlineClose } from 'react-icons/ai'; 
import { BsFillTelephoneFill } from 'react-icons/bs'; 
import { MdEmail } from 'react-icons/md'; 
import { Helmet } from 'react-helmet'; 

const containerStyle = {
    position: 'fixed',
    inset: 0,
    background: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    zIndex: 50, // Ensure it overlays everything
};

const cardStyle = {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    padding: '3rem 1rem',
    position: 'relative',
    textAlign: 'center',
    width: '70%',
    maxWidth: '800px',
    zIndex: 100, // Ensure the card is on top of the overlay
};

const closeIconStyle = {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    cursor: 'pointer',
    color: '#3e2f22',
};

const titleStyle = {
    margin: '0.5rem 0',
    fontWeight: 'bold',
    color: '#3e2f22',
    fontSize: '2rem',
};

const contactInfoStyle = {
    margin: '1rem 0',
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#3e2f22',
};

const socialMediaWrapperStyle = {
    margin: '1rem auto',
    display: 'grid',
    placeContent: 'center',
    fontSize: '1.5rem',
};

const linksStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
};

const linkStyle = {
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    width: '3rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    zIndex: 1,
    cursor: 'pointer',
    transition: 'all 0.3s',
};

const hoverStyle = {
    transform: 'scale(1.2)', // Scale up on hover
};

const ContactUs = ({ isOpen, toggleDialog }) => {
    const [hoveredIcon, setHoveredIcon] = useState(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                toggleDialog(); // Close the dialog when Esc is pressed
            }
        };

        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, toggleDialog]);

    const handleOverlayClick = (e) => {
        // Close the dialog when clicking outside the modal
        if (e.target === e.currentTarget) {
            toggleDialog();
        }
    };

    return (
        <>
            <Helmet>
                <title>ContactUs | TaaB4 Company</title>
                <meta name="description" content="Explore TaaB4 Company's services including Software Development, Web Development, Web Design, and Mobile Application development." />
            </Helmet>
            {isOpen && (
                <div style={containerStyle} onClick={handleOverlayClick}>
                    <div style={cardStyle}>
                        <AiOutlineClose 
                            style={closeIconStyle} 
                            size={24} 
                            onClick={toggleDialog} // Close when the close icon is clicked
                        />
                        <div style={titleStyle}>Contact Us</div>
                        <div style={contactInfoStyle}>
                            <MdEmail style={{ marginRight: '0.5rem', color: '#3e2f22' }} size={24} />
                            <strong>Email:</strong> info@taab4.com
                        </div>
                        <div style={contactInfoStyle}>
                            <BsFillTelephoneFill style={{ marginRight: '0.5rem', color: '#3e2f22', whiteSpace: 'nowrap' }} size={24} />
                            <strong>Phone:</strong> +971 508922504
                        </div>
                        <div style={socialMediaWrapperStyle}>
                            <div style={linksStyle}>
                                <a 
                                    href="https://www.linkedin.com/company/taab4-company/?viewAsMember=true" 
                                    style={{
                                        ...linkStyle,
                                        backgroundColor: '#0077b5', // LinkedIn color
                                        ...(hoveredIcon === 'linkedin' ? hoverStyle : {}),
                                    }}
                                    onMouseEnter={() => setHoveredIcon('linkedin')}
                                    onMouseLeave={() => setHoveredIcon(null)}
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                >
                                    <FaLinkedin size={30} />
                                </a>
                                <a 
                                    href="https://wa.me/+971508922504" 
                                    style={{
                                        ...linkStyle,
                                        backgroundColor: '#25D366', // WhatsApp color
                                        ...(hoveredIcon === 'whatsapp' ? hoverStyle : {}),
                                    }}
                                    onMouseEnter={() => setHoveredIcon('whatsapp')}
                                    onMouseLeave={() => setHoveredIcon(null)}
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                >
                                    <FaWhatsapp size={30} />
                                </a>
                                <a 
                                    href="https://www.instagram.com/_taab4/" 
                                    style={{
                                        ...linkStyle,
                                        backgroundColor: '#E1306C', // Instagram color
                                        ...(hoveredIcon === 'instagram' ? hoverStyle : {}),
                                    }}
                                    onMouseEnter={() => setHoveredIcon('instagram')}
                                    onMouseLeave={() => setHoveredIcon(null)}
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                >
                                    <FaInstagram size={30} />
                                </a>
                                <a 
                                    href="https://www.facebook.com/profile.php?id=61562403166741" 
                                    style={{
                                        ...linkStyle,
                                        backgroundColor: '#3b5998', // Facebook color
                                        ...(hoveredIcon === 'facebook' ? hoverStyle : {}),
                                    }}
                                    onMouseEnter={() => setHoveredIcon('facebook')}
                                    onMouseLeave={() => setHoveredIcon(null)}
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                >
                                    <FaFacebook size={30} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ContactUs;
