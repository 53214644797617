import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavLinks = ({ onLinkClick, toggleContactDialog }) => {
  const linkStyle = {
    padding: "0.5rem 1rem",
    fontWeight: "800",
    color: "#4B5563",
    textDecoration: "none",
    cursor: "pointer",
    whiteSpace: "nowrap",
  };

  const handleScroll = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
    onLinkClick(); // Close the menu when a link is clicked
  };

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
    document.body.setAttribute("dir", newLanguage === "ar" ? "rtl" : "ltr");
  };

  return (
    <>
      <style>
        {`
          .switch {
            position: relative;
            display: inline-block;
            margin: 0 5px;
          }

          .switch > span {
            position: absolute;
            top: 10px;
            pointer-events: none;
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
            width: 50%;
            text-align: center;
          }

          input.check-toggle-round-flat:checked ~ .off {
            color: #000000;
          }

          input.check-toggle-round-flat:checked ~ .on {
            color: #fff;
          }

          .switch > span.on {
            left: 0;
            padding-left: 2px;
            color: #0000000;
          }

          .switch > span.off {
            right: 0;
            padding-right: 4px;
            color: #fff;
          }

          .check-toggle {
            position: absolute;
            margin-left: -9999px;
            visibility: hidden;
          }
          .check-toggle + label {
            display: block;
            position: relative;
            cursor: pointer;
            outline: none;
            user-select: none;
          }

          input.check-toggle-round-flat + label {
            padding: 2px;
            width: 97px;
            height: 35px;
            background-color: #82868A;
            border-radius: 60px;
          }

          input.check-toggle-round-flat + label:before,
          input.check-toggle-round-flat + label:after {
            display: block;
            position: absolute;
            content: "";
          }

          input.check-toggle-round-flat + label:before {
            top: 2px;
            left: 2px;
            bottom: 2px;
            right: 2px;
            background-color: #82868A;
            border-radius: 60px;
          }

          input.check-toggle-round-flat + label:after {
            top: 4px;
            left: 4px;
            bottom: 4px;
            width: 48px;
            background-color: #fff;
            border-radius: 52px;
            transition: margin 0.2s;
          }

          input.check-toggle-round-flat:checked + label:after {
            margin-left: 44px;
          }
          .uppercase {
            text-transform: uppercase;
          }
        `}
      </style>
      <nav className="flex flex-col lg:flex-row items-start nowrap">
        <Link style={linkStyle} to="/" onClick={onLinkClick}>
          {t("navBar.Home")}
        </Link>
        <Link
          style={linkStyle}
          to="/#about"
          onClick={(e) => handleScroll(e, "about")}
        >
          {t("navBar.About")}
        </Link>
        <Link
          style={linkStyle}
          to="/#services"
          onClick={(e) => handleScroll(e, "services")}
        >
          {t("navBar.Services")}
        </Link>
        <Link
          style={linkStyle}
          to="/#portfolio"
          onClick={(e) => handleScroll(e, "clients")}
        >
          {t("navBar.Clients")}
        </Link>
        <span style={linkStyle} onClick={toggleContactDialog}>
          {t("navBar.Contact Us")}
        </span>
        <div className="navbar-language-toggle hidden lg:block">
          <div className="switch">
            <input
              id="language-toggle"
              className="check-toggle check-toggle-round-flat"
              type="checkbox"
              checked={currentLanguage === "ar"}
              onChange={toggleLanguage}
            />
            <label htmlFor="language-toggle"></label>
            <span className="on uppercase">ع</span>
            <span className="off">EN</span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavLinks;
