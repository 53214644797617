import React, { useState, useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { useTranslation } from 'react-i18next';
import "./i18n"; // Import the i18n configuration

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./pages/NotFound"; // Import your NotFound component

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  useDocTitle("Taab4 Company");

  const { t, i18n } = useTranslation(); // `t` is used to access translations
  const [language, setLanguage] = useState("en"); // State to track the selected language

  const handleLanguageChange = (lang) => {
    console.log(`Switching to language: ${lang}`);
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };
  
  // Change the direction of the body based on the language
  useEffect(() => {
    document.body.setAttribute("dir", language === "ar" ? "rtl" : "ltr");
  }, [language]);

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            {/* <Route path="/contact" element={<Contact />} /> */}
            {/* <Route path="/get-demo" element={<DemoProduct />} />  */}
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
