import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from '../Navbar/NavLinks';
import Logo from '../../images/logo-normal.jpeg';
import ContactUs from '../ContactUs'; // Import the modal
import { useTranslation } from "react-i18next";

const NavBar = () => {
    const [top, setTop] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isContactOpen, setContactOpen] = useState(false); // Manage modal state here
    const mobileMenuRef = useRef(null); // Ref for the mobile menu

    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    // Function to toggle between languages
    const toggleLanguage = () => {
        const newLanguage = currentLanguage === "en" ? "ar" : "en";
        i18n.changeLanguage(newLanguage);
        document.body.setAttribute("dir", newLanguage === "ar" ? "rtl" : "ltr");
    };

    const handleClick = () => setIsOpen(!isOpen);
    const closeMenu = () => setIsOpen(false);

    // Function to toggle the contact modal
    const toggleContactDialog = () => {
        setContactOpen(!isContactOpen);
        closeMenu(); // Close the menu when opening the modal
    };

    // Close the menu if clicked outside
    const handleClickOutside = (event) => {
        if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
            closeMenu();
        }
    };

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true);
        };
        window.addEventListener('scroll', scrollHandler);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <style>
                {`
                    .switch {
                        position: relative;
                        display: inline-block;
                        margin: 0 5px;
                    }

                    .switch > span {
                        position: absolute;
                        top: 10px;
                        pointer-events: none;
                        font-weight: bold;
                        font-size: 12px;
                        text-transform: uppercase;
                        text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
                        width: 50%;
                        text-align: center;
                    }

                    input.check-toggle-round-flat:checked ~ .off {
                        color: #000;
                    }

                    input.check-toggle-round-flat:checked ~ .on {
                        color: #fff;
                    }

                    .switch > span.on {
                        left: 0;
                        padding-left: 2px;
                        color: #000;
                    }

                    .switch > span.off {
                        right: 0;
                        padding-right: 4px;
                        color: #fff;
                    }

                    .check-toggle {
                        position: absolute;
                        margin-left: -9999px;
                        visibility: hidden;
                    }
                    .check-toggle + label {
                        display: block;
                        position: relative;
                        cursor: pointer;
                        outline: none;
                        user-select: none;
                    }

                    input.check-toggle-round-flat + label {
                        padding: 2px;
                        width: 97px;
                        height: 35px;
                        background-color: #82868A;
                        border-radius: 60px;
                    }

                    input.check-toggle-round-flat + label:before,
                    input.check-toggle-round-flat + label:after {
                        display: block;
                        position: absolute;
                        content: "";
                    }

                    input.check-toggle-round-flat + label:before {
                        top: 2px;
                        left: 2px;
                        bottom: 2px;
                        right: 2px;
                        background-color: #82868A;
                        border-radius: 60px;
                    }

                    input.check-toggle-round-flat + label:after {
                        top: 4px;
                        left: 4px;
                        bottom: 4px;
                        width: 48px;
                        background-color: #fff;
                        border-radius: 52px;
                        transition: margin 0.2s;
                    }

                    input.check-toggle-round-flat:checked + label:after {
                        margin-left: 44px;
                    }
                    .uppercase {
                        text-transform: uppercase;
                    }
                `}
            </style>
            <nav className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${!top && 'bg-white shadow-lg'}`}>
                <div className="flex flex-row justify-between items-center px-6 w-full sm:px-6 max-md:py-2">
                    <div className="flex items-center space-x-2 sm:space-x-4 w-full justify-start pl-2 sm:pl-10">
                        <Link to="/" className="overflow-hidden p-0 flex justify-center transition-all ease-in-out hover:opacity-100 rounded-2xl">
                            <img src={Logo} alt="client" style={{ height: '3rem', width: 'auto' }} className="object-contain" />
                        </Link>
                        <Link to="/" className="text-black ml-2 sm:ml-4">
                            <h1 className="font-extrabold text-xl sm:text-4xl whitespace-nowrap" style={{ fontFamily: "'Pacifico', cursive", fontWeight: 700 }}>TaaB4</h1>
                        </Link>
                    </div>

                    {/* Language Toggle */}
                    <div className="navbar-language-toggle lg:hidden">
                        <div className="switch">
                            <input
                                id="language-toggle"
                                className="check-toggle check-toggle-round-flat"
                                type="checkbox"
                                checked={currentLanguage === "ar"}
                                onChange={toggleLanguage}
                            />
                            <label htmlFor="language-toggle"></label>
                            <span className="on uppercase">ع</span>
                            <span className="off">EN</span>
                        </div>
                    </div>

                    <div className="flex items-center lg:hidden">
                        <button className="p-2 rounded-lg text-black" onClick={handleClick}>
                            <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                {isOpen ? (
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                                ) : (
                                    <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                                )}
                            </svg>
                        </button>
                    </div>

                    <div className="hidden space-x-6 lg:inline-block p-5 me-7">
                        <NavLinks onLinkClick={closeMenu} toggleContactDialog={toggleContactDialog} />
                    </div>
                </div>

                {/* Mobile menu (NavLinks) */}
                {isOpen && (
                    <div ref={mobileMenuRef} className="lg:hidden flex flex-col space-y-4 p-6 bg-white shadow-lg absolute top-0 left-0 w-full z-40 justify-center items-center">
                        <NavLinks onLinkClick={closeMenu} toggleContactDialog={toggleContactDialog} />
                    </div>
                )}
            </nav>

            {/* Conditional rendering of ContactUs modal */}
            {isContactOpen && <ContactUs isOpen={isContactOpen} toggleDialog={toggleContactDialog} />}
        </>
    );
};

export default NavBar;
