import React from "react";
import group from "../images/group_921296.png";
import targeting from "../images/targeting.png";
import about from "../images/about-intro.svg";
import our from "../images/Vision.svg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Intro = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>About & Vision | TaaB4 Company</title>
        <meta
          name="description"
          content="Learn more about TaaB4 Company and our vision for delivering innovative, custom software solutions to empower businesses."
        />
      </Helmet>
      <div className="m-auto max-w-8xl p-4 md:p-14 h-5/6 pb-5 mt-24" id="about">
        <div className="flex flex-col lg:flex-row gap-8 mt-8">
          {/* About Section */}
          <div
            className="flex flex-col flex-1 text-center mb-8"
            data-aos="fade-up"
          >
            <div className="flex justify-center mb-4">
              <img
                alt="About illustration"
                className="rounded-full duration-1000 w-[40%]"
                src={about}
              />
            </div>
            <h3 className="text-xl md:text-2xl lg:text-3xl font-bold mb-2 flex items-center justify-center">
              <img
                alt="group illustration"
                className="rounded-2xl duration-1000 w-8 h-8 mr-2"
                src={group}
              />
              <h3 className="text-3xl text-black font-bold">
                <span className="font-black">
                  {t("aboutUsSection.firstTitle")}
                </span>
              </h3>
            </h3>

            <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-gray-600 font-semibold px-4 md:px-0">
              {t("aboutUsSection.subFirstTitle")}
            </p>
          </div>

          {/* Vision Section */}
          <div
            className="flex flex-col flex-1 text-center mb-8"
            data-aos="fade-up"
          >
            <div className="flex justify-center mb-4">
              <img
                alt="Our Vision illustration"
                className="rounded-t duration-1000 w-[40%]"
                src={our}
              />
            </div>
            <h3 className="text-xl md:text-2xl lg:text-3xl font-bold mb-2 flex items-center justify-center">
              <img
                alt="vision illustration"
                className="rounded-2xl w-8 h-8 mr-2"
                src={targeting}
              />
              <h3 className="text-3xl text-black font-bold">
                <span className="font-black">
                  {t("aboutUsSection.secondTitle")}
                </span>
              </h3>
            </h3>
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-gray-600 font-semibold px-4 md:px-0">
              {t("aboutUsSection.subSecondTitle")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;